<template>
  <div class="chart-container">
    <div class="v-chart" ref="chartContainer"></div>
    <div v-if="noData" class="no-data-container">
      <v-icon color="grey lighten-1" size="48">mdi-database-off</v-icon>
      <p class="no-data-text">No Data Reported</p>
      <p class="no-data-subtext">
        Try adjusting your filters or check the data source.
      </p>
    </div>
  </div>
</template>

<script>
import echarts from "echarts/lib/echarts";
import "echarts/lib/chart/bar";
import "echarts/lib/component/title";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/grid";
import "echarts/lib/component/legend";

export default {
  name: "BarChart",
  props: {
    title: {
      type: String,
      required: false,
    },
    series: {
      type: Array,
      required: true,
    },
    categories: {
      type: Array,
      required: true,
    },
    xAxisTitle: {
      type: String,
      default: "",
    },
    yAxisTitle: {
      type: String,
      default: "",
    },
    chartType: {
      type: String,
      default: "basic", // 'grouped', 'stacked', 'basic'
    },
    orientation: {
      type: String,
      default: "vertical", // 'vertical', 'horizontal'
    },
    isMonthly: {
      type: Boolean,
      required: true,
    },
    metric: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      chart: null,
      noData: false,
    };
  },
  mounted() {
    this.initChart();
    window.addEventListener("resize", this.resizeChart);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resizeChart);
    if (this.chart) {
      this.chart.dispose();
    }
  },
  methods: {
    initChart() {
      const container = this.$refs.chartContainer;
      this.chart = echarts.init(container, null, { resize: true });
      this.updateChartOptions();
    },
    resizeChart() {
      if (this.chart) {
        this.chart.resize();
      }
    },
    getMetricValue(kpi, { value, hours }) {
      const { type } = kpi;
      return (
        {
          percentage: () => this.formatPercentage(value),
          fraction: () => this.formatFraction(kpi, value, hours),
        }[type]?.() || this.formatDefault(value)
      );
    },

    formatPercentage(value) {
      return value ? `${value.toFixed(0)}%` : "-";
    },

    formatFraction({ fraction_unit }, value, time) {
      if (fraction_unit === "Hours") {
        const days = Math.floor(time / 24);
        const hours = (time % 24).toFixed(0);
        return (
          [
            days > 0 ? `${days} day(s)` : "",
            hours > 0 ? `${hours} hour(s)` : "",
          ]
            .filter(Boolean)
            .join(" and ") || "0 hours"
        );
      } else if (fraction_unit === "Percent") {
        return `${value.toFixed(0)}%`;
      }
      return "-";
    },

    formatDefault(value) {
      return value || "-";
    },

    formatTitle(title, screenWidth) {
      const maxLineLength = screenWidth <= 1400 ? 40 : 75;
      let words = title.split(" ");
      let currentLine = "";
      let formattedTitle = "";

      words.forEach((word) => {
        if ((currentLine + word).length > maxLineLength) {
          // Add the current line and start a new one
          formattedTitle += currentLine.trim() + "\n";
          currentLine = word + " ";
        } else {
          currentLine += word + " ";
        }
      });

      formattedTitle += currentLine.trim();

      return formattedTitle;
    },

    isPercentage(metric) {
      return (
        this.metric.type === "percentage" ||
        (this.metric.type === "fraction" &&
          this.metric.fraction_unit === "Percent")
      );
    },

    createSeriesData() {
      if (this.chartType === "stacked") {
        return this.series.map((series) => ({
          name: series.name,
          type: "bar",
          stack: "total",
          data: series.data.map((item) => ({
            value:
              this.metric.fraction_unit === "Hours"
                ? (item.value / 24).toFixed(2)
                : parseFloat(item.value),
            note: item.note,
            hours: this.metric.fraction_unit === "Hours" ? item.value : null,
          })),
        }));
      } else if (this.chartType === "grouped") {
        return this.series.map((series) => ({
          name: series.name,
          type: "bar",
          data: series.data.map((item) => ({
            value:
              this.metric.fraction_unit === "Hours"
                ? (item.value / 24).toFixed(2)
                : parseFloat(item.value),
            note: item.note,
            hours: this.metric.fraction_unit === "Hours" ? item.value : null,
          })),
        }));
      } else {
        // Basic bar chart
        return [
          {
            name: this.series[0].name,
            type: "bar",
            data: this.series[0].data.map((item) => ({
              value:
                this.metric.fraction_unit === "Hours"
                  ? (item.value / 24).toFixed(2)
                  : parseFloat(item.value),
              note: item.note,
              hours: this.metric.fraction_unit === "Hours" ? item.value : null,
            })),
          },
        ];
      }
    },

    formatAxisLabel(value, orientation) {
      if (this.isPercentage(this.metric) && this.orientation === orientation) {
        return `${value}%`;
      } else if (
        this.metric.fraction_unit === "Hours" &&
        this.orientation === orientation
      ) {
        return `${value}d`;
      } else {
        return value;
      }
    },

    getLegendOptions(seriesNames, screenWidth) {
      return {
        data: seriesNames,
        orient: "horizontal",
        top: "bottom",
        left: "center",
        textStyle: { fontSize: screenWidth <= 1000 ? 10 : 12 },
      };
    },

    createTooltipFormatter(params) {
      let tooltipText = params[0].name + "<br/>";
      params.forEach((param) => {
        let value = this.getMetricValue(this.metric, param.data);
        let notes = param.data.note
          ? Array.isArray(param.data.note)
            ? param.data.note
            : [param.data.note]
          : [];

        if (param.seriesName) {
          tooltipText += `${param.marker} ${param.seriesName}: ${value}<br/>`;
        } else {
          tooltipText += `${param.marker} ${value}<br/>`;
        }

        if (notes) {
          notes.forEach((note) => {
            const infoIcon = `
      <span style="
        display: inline-block; 
        width: 14px; 
        height: 14px; 
        border-radius: 50%; 
        background-color: #17bebb; 
        color: white; 
        text-align: center; 
        line-height: 14px; 
        font-size: 11px; 
        font-weight: bold;
        margin-right: 8px;
      ">&#x2139;</span>`;

            tooltipText += `${infoIcon}${note}<br/>`; // Add each note
          });
        }
      });
      return tooltipText;
    },

    calculateTitleHeight(text, fontSize) {
      const lines = text.split("\n").length;
      return lines * fontSize;
    },

    updateChartOptions() {
      const screenWidth = window.innerWidth;
      const formattedTitle = this.formatTitle(this.title, screenWidth);

      const options = {
        color: ["#5872c0", "#9eca7f", "#F5A623", "#D64242", "#F7E52D"],
        title: [
          {
            text: formattedTitle,
            subtext: !this.isMonthly ? `${this.metric.aggregation}` : "",
            left: "center",
            top: "2%",
            textStyle: {
              fontSize: screenWidth <= 1000 ? 12 : 16,
              fontWeight: "bold",
            },
            subtextStyle: {
              fontSize: screenWidth <= 1000 ? 8 : 12,
              fontWeight: "bold",
              color: "#5b5b5b",
            },
          },
        ],
      };

      this.noData =
        this.series.length === 0 ||
        this.series.every((series) => !series.data || series.data.length === 0);

      if (this.noData) {
        this.chart.setOption(options);
        return;
      }

      const maxItems = this.isMonthly
        ? 12
        : !this.isMonthly
        ? 8
        : this.series.length;

      let categories = this.categories.slice(-maxItems);

      if (this.orientation === "horizontal") {
        categories = categories.reverse();
      }
      const seriesData = this.createSeriesData();

      Object.assign(options, {
        color: ["#5872c0", "#9eca7f", "#F5A623", "#D64242", "#F7E52D"],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          confine: true, // Ensure tooltip stays within the chart
          textStyle: {
            fontSize: screenWidth <= 1000 ? 10 : 12,
          },
          padding: [10, 20],
          extraCssText: `
    max-width: 350px;
    white-space: normal;
    word-wrap: break-word;
    overflow: hidden;
  `,
          position: function(point, params, dom, rect, size) {
            const [x, y] = point;
            const viewWidth = size.viewSize[0];
            const viewHeight = size.viewSize[1];
            const domWidth = dom.offsetWidth;
            const domHeight = dom.offsetHeight;

            // Handle horizontal position
            let posX = x;
            if (x < 10) {
              posX = 10; // Too close to the left
            } else if (x + domWidth + 10 > viewWidth) {
              posX = viewWidth - domWidth - 20; // Too close to the right
            }

            // Handle vertical position
            let posY = y;
            if (y < 10) {
              posY = 10; // Too close to the top
            } else if (y + domHeight + 10 > viewHeight) {
              posY = viewHeight - domHeight - 20; // Too close to the bottom
            }

            return [posX, posY];
          },
          formatter: (params) => this.createTooltipFormatter(params),
        },

        grid: {
          left: screenWidth <= 1000 ? "10%" : this.yAxisTitle ? "8%" : "3%",
          right: screenWidth <= 1000 ? "10%" : "5%",
          bottom: "15%",
          top: formattedTitle.includes("\n") && !this.isMonthly ? "20%" : "15%",
          containLabel: true,
        },

        xAxis: {
          type: "category",
          name: this.xAxisTitle,
          nameLocation: "center",
          nameGap: 40,
          nameTextStyle: {
            fontSize: screenWidth <= 1000 ? 10 : 12,
          },
          min:
            this.isPercentage(this.metric) && this.orientation === "horizontal"
              ? 0
              : null,
          max:
            this.isPercentage(this.metric) && this.orientation === "horizontal"
              ? 100
              : null,
          data: categories,
          axisLabel: {
            formatter: (value) => this.formatAxisLabel(value, "horizontal"),
            fontSize: screenWidth <= 1000 ? 10 : 12,
          },
        },
        yAxis: {
          type: "value",
          name: this.yAxisTitle,
          nameLocation: "center",
          nameGap: 40,
          nameTextStyle: {
            fontSize: screenWidth <= 1000 ? 10 : 12,
          },
          min:
            this.isPercentage(this.metric) && this.orientation === "vertical"
              ? 0
              : null,
          max:
            this.isPercentage(this.metric) && this.orientation === "vertical"
              ? 100
              : null,
          axisLabel: {
            formatter: (value) => this.formatAxisLabel(value, "vertical"),
            fontSize: screenWidth <= 1000 ? 10 : 12,
          },
        },
        series: seriesData,
      });

      if (this.orientation === "horizontal") {
        options.xAxis.type = "value";
        options.yAxis.type = "category";
        options.yAxis.data = categories;
        delete options.xAxis.data;
      }

      if (this.chartType !== "basic") {
        options.legend = this.getLegendOptions(
          this.series
            .map((series) => series.name)
            .sort((a, b) => a.localeCompare(b)),
          screenWidth
        );
      }

      this.chart.setOption(options);
    },
  },
  watch: {
    title: "updateChartOptions",
    series: "updateChartOptions",
    categories: "updateChartOptions",
    xAxisTitle: "updateChartOptions",
    yAxisTitle: "updateChartOptions",
    chartType: "updateChartOptions",
    orientation: "updateChartOptions",
    frequency: "updateChartOptions",
  },
};
</script>

<style scoped>
.chart-container {
  width: 100%;
  height: 400px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.v-chart {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.no-data-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  animation: fadeIn 0.7s ease-in-out;
}

.no-data-text {
  font-size: 18px;
  font-weight: bold;
  color: #666;
}

.no-data-subtext {
  font-size: 14px;
  color: #999;
  margin-top: 8px;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
