<template>
  <v-card class="mx-auto" max-width="1000" style="margin: 20px">
    <v-toolbar
      color="secondary"
      style="background: #3f3f3f !important; color: #ffffff !important"
    >
      <v-toolbar-title
        class="hidden-xs-only"
        v-if="currentPathString.length > 0"
      >
        {{ currentPathString[currentPathString.length - 1].name }}
      </v-toolbar-title>
      <v-toolbar-title class="hidden-xs-only" v-else>
        Home
      </v-toolbar-title>
      <div class="custom-toolbar">
        <v-text-field
          v-model="search"
          label="Search"
          solo-inverted
          hide-details
          class="custom-text-field"
        ></v-text-field>
        <v-btn icon class="hidden-xs-only">
          <v-icon color="white">mdi-magnify</v-icon>
        </v-btn>
        <v-btn icon>
          <v-icon color="white" @click="goHome">mdi-home</v-icon>
        </v-btn>
        <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <v-btn
              class="text-none text-subtitle-1"
              color="#17BEBB"
              variant="flat"
              v-on="on"
            >
              <span style="color: #fff">Add</span>
              <v-icon color="white" right>mdi-menu-down</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item @click="showUploadFileDialog = true">
              <v-list-item-title>File</v-list-item-title>
            </v-list-item>
            <v-list-item @click="showCreateFolderDialog = true">
              <v-list-item-title>Folder</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-toolbar>
    <v-dialog v-model="showUploadFileDialog" max-width="500">
      <v-card>
        <v-card-title class="headline">Upload File</v-card-title>
        <v-form ref="fileForm" v-model="valid" lazy-validation>
          <v-card-text>
            <v-file-input
              label="Select a file"
              v-model="fileToUpload"
              :rules="[(v) => !!v || 'File is required']"
              append-icon="mdi-cloud-upload"
              ><template #label>
                <span class="red--text"><strong class="mr-2">*</strong></span
                >Select a File
              </template></v-file-input
            >
            <v-text-field
              v-model="attachment.name"
              label="File Name"
            ></v-text-field>
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="attachment.associated_year"
                  label="Year"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                :active-picker.sync="activePicker"
                :max="new Date().getFullYear().toString()"
                min="1950"
                @click:year="saveYear"
                no-title
                reactive
              ></v-date-picker>
            </v-menu>
          </v-card-text>
          <v-card-actions>
            <v-btn @click="uploadFile()" color="#17BEBB" dark>
              <span v-if="uploading">
                <v-progress-circular
                  indeterminate
                  size="20"
                  color="white"
                ></v-progress-circular>
              </span>
              <span v-else>Upload</span>
            </v-btn>
            <v-btn @click="closeModal">Cancel</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showCreateFolderDialog" max-width="500">
      <v-card>
        <v-card-title class="headline">Create Folder</v-card-title>
        <v-form ref="folderForm" lazy-validation>
          <v-card-text>
            <v-text-field
              v-model="newFolder.name"
              label="Folder Name"
              :rules="[(v) => !!v || 'Folder Name is required']"
              ><template #label>
                <span class="red--text"><strong class="mr-2">*</strong></span
                >Folder Name
              </template>
            </v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-btn @click="createFolder()" color="#17BEBB" dark>
              <span>Submit</span>
            </v-btn>
            <v-btn @click="closeModal">Cancel</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbarVisible" :timeout="3000" color="#17BEBB">
      {{ confirmationMessage }}
    </v-snackbar>
    <delete-confirmation-dialog
      :dialog="showDeleteDialog"
      :message="
        itemToDelete
          ? itemIsFolder(itemToDelete)
            ? `Are you sure you want to delete the folder: <strong>${itemToDelete.name}</strong> and all of its associated files?`
            : `Are you sure you want to delete the file: <strong>${itemToDelete.name}</strong>?`
          : ''
      "
      @delete="itemIsFolder(itemToDelete) ? deleteFolder() : deleteFile()"
      @cancel="closeModal"
    ></delete-confirmation-dialog>
    <div
      id="drop-area"
      @dragenter.prevent="($event) => $event.preventDefault()"
      @dragover.prevent="($event) => $event.preventDefault()"
      @drop="handleDrop"
    >
      <v-list subheader two-line>
        <v-subheader inset v-if="currentPath.length > 0">
          <v-icon small color="var(--aqua)" @click="goHome">mdi-home</v-icon>
          <span
            v-for="(part, index) in currentPathString"
            :key="part.id"
            @click="handlePathClick(part.id, index)"
            :style="{ cursor: 'pointer', color: 'var(--aqua)' }"
          >
            <strong
              >&nbsp;&nbsp;<strong style="color: var(--grey);">&gt;</strong
              >&nbsp;&nbsp;</strong
            >
            <span class="path">{{ part.name }}</span>
          </span>
        </v-subheader>

        <template v-for="folder in currentFolderSubfolders">
          <v-list-item
            v-if="currentPath"
            :key="folder.id"
            @click="folder.updateMode ? null : handleItemClick(folder)"
            :ripple="!folder.updateMode"
            @dragover.prevent="$set(highlightedFolders, folder.id, true)"
            @dragleave.prevent="$set(highlightedFolders, folder.id, false)"
            @drop.prevent="moveItemToFolder(folder.id)"
            :class="{ 'highlighted-folder': highlightedFolders[folder.id] }"
          >
            <div
              :draggable="!folder.updateMode ? true : false"
              @dragstart="draggedItem = folder"
              class="d-flex align-items-center"
            >
              <v-list-item-avatar>
                <v-icon class="grey lighten-1" dark>
                  mdi-folder
                </v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <template v-if="!folder.updateMode">
                  <v-list-item-title v-text="folder.name"></v-list-item-title>
                  <v-list-item-subtitle>{{
                    folder.updated_date | formatDate
                  }}</v-list-item-subtitle>
                </template>
                <template v-else>
                  <v-text-field
                    v-model="folder.name"
                    @blur="updateFolder(folder)"
                    :rules="[(v) => !!v || 'Folder Name is required']"
                    ><template #label>
                      <span class="red--text"
                        ><strong class="mr-2">*</strong></span
                      >File Name
                    </template>
                    ></v-text-field
                  >
                </template>
              </v-list-item-content>
            </div>
            <v-list-item-action class="ml-auto">
              <v-menu v-model="showFolderMenu[folder.id]" offset-y>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" icon>
                    <v-icon color="grey lighten-1">mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="updateItem(folder)">
                    <v-list-item-icon>
                      <v-icon small>mdi-pencil</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title class="caption"
                      >Update</v-list-item-title
                    >
                  </v-list-item>
                  <v-list-item @click="deleteItem(folder)">
                    <v-list-item-icon>
                      <v-icon small>mdi-delete</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title class="caption"
                      >Delete</v-list-item-title
                    >
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-list-item-action>
          </v-list-item>
        </template>
        <div
          @dragover.prevent="isFileAreaHighlighted = true"
          @dragleave.prevent="isFileAreaHighlighted = false"
          @drop.prevent="isFileAreaHighlighted = false"
          :class="{ 'highlighted-file-area': isFileAreaHighlighted }"
        >
          <div v-for="file in currentFolderFiles" :key="file.id">
            <v-tooltip bottom content-class="error-tooltip">
              <template v-slot:activator="{ on, attrs }">
                <v-list-item
                  @click="file.updateMode ? null : handleItemClick(file)"
                  :ripple="!file.updateMode"
                  v-bind="attrs"
                  v-on="
                    accessGroup && !permissions.includes(accessGroup) ? on : {}
                  "
                  :class="{
                    'greyed-out':
                      accessGroup && !permissions.includes(accessGroup),
                  }"
                >
                  <div
                    :draggable="!file.updateMode ? true : false"
                    @dragstart="draggedItem = file"
                    class="d-flex align-items-center"
                  >
                    <v-list-item-avatar>
                      <v-icon
                        :style="{
                          color: getIconColor(fileTypes['type'][file.type]),
                        }"
                        dark
                      >
                        {{
                          fileTypes["type"][file.type] ||
                            "mdi-file-document-multiple"
                        }}
                      </v-icon>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <template v-if="!file.updateMode">
                        <v-list-item-title
                          v-text="file.name"
                        ></v-list-item-title>
                        <v-list-item-subtitle>
                          <span>{{ file.updated_date | formatDate }}</span>
                          <span v-if="file.associated_year" class="mx-3"
                            >•</span
                          >
                          <span>{{ file.associated_year }}</span>
                        </v-list-item-subtitle>
                      </template>
                      <template v-else>
                        <div class="d-flex flex-column">
                          <v-text-field
                            v-model="file.name"
                            @blur="updateFile(file)"
                            :rules="[(v) => !!v || 'File Name is required']"
                            ><template #label>
                              <span class="red--text"
                                ><strong class="mr-2">*</strong></span
                              >File Name
                            </template>
                          </v-text-field>
                          <v-menu
                            ref="menu"
                            v-model="menu"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="file.associated_year"
                                label="Year"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              :active-picker.sync="activePicker"
                              :max="new Date().getFullYear().toString()"
                              min="1950"
                              @click:year="(year) => updateYear(file, year)"
                              no-title
                              reactive
                            ></v-date-picker>
                          </v-menu>
                        </div>
                      </template>
                    </v-list-item-content>
                  </div>

                  <v-list-item-action class="ml-auto">
                    <v-menu v-model="showFileMenu[file.id]" offset-y>
                      <template v-slot:activator="{ on }">
                        <!-- Use a single mdi-dots-vertical icon here -->
                        <v-btn v-on="on" icon>
                          <v-icon color="grey lighten-1"
                            >mdi-dots-vertical</v-icon
                          >
                        </v-btn>
                      </template>

                      <!-- Dropdown menu items for update and delete -->
                      <v-list>
                        <v-list-item @click="updateItem(file)">
                          <v-list-item-icon>
                            <v-icon small>mdi-pencil</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title class="caption"
                            >Update</v-list-item-title
                          >
                        </v-list-item>
                        <v-list-item @click="deleteItem(file)">
                          <v-list-item-icon>
                            <v-icon small>mdi-delete</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title class="caption"
                            >Delete</v-list-item-title
                          >
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-list-item-action>
                </v-list-item>
              </template>
              <span>Sorry, you don't have permission to access this file.</span>
            </v-tooltip>
          </div>
          <div class="text-center py-4">
            <span v-if="uploading && !showUploadFileDialog">
              <v-progress-circular
                indeterminate
                size="20"
                color="var(--aqua)"
              ></v-progress-circular>
            </span>
            <div v-else>
              <v-icon dark left color="var(--light-grey)"
                >mdi-cloud-upload</v-icon
              >
              <span class="grey--text text-body-2">Drop files here</span>
            </div>
          </div>
        </div>
      </v-list>
    </div>
  </v-card>
</template>

<style>
.icon-wrapper {
  background-color: #17bebb;
}
.custom-toolbar {
  margin-left: auto;
  display: flex;
  align-items: center;
}
.highlighted-folder {
  background-color: #f0f0f0;
}
.path:hover {
  text-decoration: underline;
}
.highlighted-file-area {
  background-color: #f0f0f0;
}
.custom-text-field .v-input__control input {
  color: black !important;
}
.greyed-out {
  opacity: 0.5;
  cursor: not-allowed;
}
.error-tooltip {
  background-color: #f8d7da; /* Light red background */
  color: #721c24; /* Dark red text color for readability */
  border-color: #f5c6cb; /* Lighter red border */
}
</style>

<script>
import DeleteConfirmationDialog from "./DeleteConfirmationDialog.vue";
import fileTypes from "@/fileTypes.json";
import { mapGetters } from "vuex";

export default {
  props: {
    filesEndPoint: {
      type: String,
      required: false,
      default: "attachments?",
    },
    foldersEndPoint: {
      type: String,
      required: false,
      default: "folders?",
    },
    accessGroup: {
      type: String,
      required: false,
      default: null,
    },
  },
  components: {
    DeleteConfirmationDialog,
  },
  data: () => ({
    // File and Attachment-related data
    selectedFolderId: "",
    fileToUpload: null,
    attachment: {
      name: null,
      associated_year: "",
    },
    uploading: false,
    valid: true,
    files: [],
    showFileMenu: {},
    showDeleteDialog: false,
    showUploadFileDialog: false,
    showCreateFolderDialog: false,

    // Folder-related data
    currentPath: [],
    newFolder: {
      name: null,
      parent: null,
    },
    folders: [],
    showFolderMenu: {},
    updatingFolder: false,
    highlightedFolders: {},
    draggedItem: null,
    itemToDelete: null,
    snackbarVisible: false,
    fileTypes: fileTypes,
    isSearchVisible: false,
    isFileAreaHighlighted: false,
    search: "",
    confirmationMessage: "",
    activePicker: null,
    menu: false,
    year: "",
  }),

  created() {
    this.getFiles();
    this.getFolders();
    this.initializeUpdateMode();
  },
  computed: {
    currentFolderFiles() {
      if (this.search) {
        return this.files.filter((file) =>
          file.name.toLowerCase().includes(this.search.toLowerCase())
        );
      } else if (this.selectedFolderId) {
        return this.files.filter(
          (file) => file.folder === this.selectedFolderId
        );
      } else {
        return this.files.filter((file) => !file.folder);
      }
    },

    currentPathString() {
      return this.currentPath.map((folderId) => {
        const folder = this.folders.find((folder) => folder.id === folderId);
        if (folder) {
          return {
            name: folder.name,
            id: folderId,
          };
        } else {
          return "";
        }
      });
    },

    currentFolderSubfolders() {
      const parentId = this.currentPath[this.currentPath.length - 1];
      if (this.search) {
        return this.folders.filter((folder) =>
          folder.name.toLowerCase().includes(this.search.toLowerCase())
        );
      } else if (this.currentPath.length === 0) {
        // Display root level folders and files
        return this.folders.filter((folder) => !folder.parent);
      } else {
        // Display items in the current folder
        return this.folders.filter((folder) => folder.parent === parentId);
      }
    },

    contextName() {
      const queryStringIndex = this.filesEndPoint.indexOf("?");
      let contextName =
        queryStringIndex !== -1
          ? this.filesEndPoint.slice(queryStringIndex + 1).split("=")[0]
          : "";

      return contextName !== "team_code" ? `${contextName}` : contextName;
    },
    ...mapGetters(["permissions"]),
  },

  watch: {
    menu(val) {
      val && this.$nextTick(() => (this.activePicker = "YEAR"));
    },
  },

  methods: {
    async getFiles() {
      let url = this.filesEndPoint;
      this.files.splice(0, this.files.length);
      while (url) {
        let response = await this.$api.get(url);
        this.files.push(...response.data.results);
        url = response.data.next;
      }
    },

    async getFolders() {
      let url = this.foldersEndPoint;
      this.folders.splice(0, this.folders.length);
      while (url) {
        let response = await this.$api.get(url);
        this.folders.push(...response.data.results);
        url = response.data.next;
      }
    },

    initializeUpdateMode() {
      this.folders.forEach((folder) => {
        this.$set(folder, "updateMode", false);
      });
      this.files.forEach((file) => {
        this.$set(file, "updateMode", false);
      });
    },

    itemIsFolder(item) {
      return Object.prototype.hasOwnProperty.call(item, "parent");
    },

    goHome() {
      this.currentPath = [];
      this.selectedFolderId = "";
      this.highlightedFolders = {};
    },

    handleItemClick(item) {
      if (this.itemIsFolder(item)) {
        if (this.search) {
          this.search = "";
          const folderIds = [];
          let currentItem = item;
          while (currentItem) {
            folderIds.unshift(currentItem.id);
            currentItem = this.folders.find(
              (folder) => folder.id === currentItem.parent
            );
          }
          this.currentPath = folderIds;
        } else {
          this.currentPath.push(item.id);
        }
        this.selectedFolderId = item.id;
        this.getFiles();
        this.getFolders();
      } else {
        this.downloadFile(item);
      }
    },

    handlePathClick(folderId, index) {
      this.currentPath = this.currentPath.slice(0, index + 1);
      this.selectedFolderId = folderId;
      this.highlightedFolders = {};
    },

    createFolder() {
      if (!this.draggedItem && !this.$refs.folderForm.validate()) {
        return;
      }
      this.newFolder.parent = this.selectedFolderId;
      let contextValue =
        this.contextName === "team_code" ? "EX" : this.$route.params.id;
      this.newFolder[this.contextName] = contextValue;
      this.$api
        .post("folders", this.newFolder)
        .then((response) => {
          this.setConfirmationMessage("Folder created successfully!");
          this.closeModal();
          this.getFolders();
        })
        .catch((error) => {
          console.error("Error creating folder:", error);
        });
    },

    updateFolder(folder) {
      if (folder.name === "") {
        return;
      }
      this.$api
        .put(`folders/${folder.id}`, folder)
        .then((response) => {
          this.setConfirmationMessage("Folder updated successfully!");
          this.getFolders();
        })
        .catch((error) => {
          console.error("Error updating folder:", error);
        })
        .finally(() => {
          folder.updateMode = false;
        });
    },

    deleteFolder() {
      this.$api
        .delete(`folders/${this.itemToDelete.id}`, this.itemToDelete)
        .then((response) => {
          this.setConfirmationMessage("Folder deleted successfully!");
          this.getFolders();
          this.showDeleteDialog = false;
        })
        .catch((error) => {
          console.error("Error deleting folder:", error);
        })
        .finally(() => {
          this.itemToDelete = null;
        });
    },

    uploadFile(folder = this.selectedFolderId) {
      if (!this.draggedItem && !this.$refs.fileForm.validate()) {
        return;
      }

      this.uploading = true;

      if (!this.attachment.name) {
        this.attachment.name = this.fileToUpload.name
          .split(".")
          .slice(0, -1)[0];
      }

      const formData = new FormData();
      formData.append("file", this.fileToUpload);
      formData.append("name", this.attachment.name);
      formData.append("associated_year", this.attachment.associated_year);
      let contextValue =
        this.contextName === "team_code" ? "EX" : this.$route.params.id;
      formData.append(this.contextName, contextValue);
      formData.append("folder", folder);

      this.$api
        .post("attachments", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.setConfirmationMessage("File uploaded successfully!");
          this.getFiles();
          this.fileToUpload = null;
          this.attachment.name = null;
          if (this.$refs.fileForm) {
            this.$refs.fileForm.resetValidation();
          }
          this.showUploadFileDialog = false;
        })
        .catch((error) => {
          console.error("Error uploading file:", error);
        })
        .finally(() => {
          this.uploading = false;
          this.draggedItem = null;
        });
    },

    downloadFile(attachment) {
      this.$api
        .get(`attachments/${attachment.id}/download/`, {
          responseType: "arraybuffer",
        })
        .then((response) => {
          const blob = new Blob([response.data], {
            type: response.headers["content-type"],
          });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = attachment.name;
          link.click();
        })
        .catch((error) => {
          console.error("Error downloading file:", error);
        });
    },

    updateFile(file) {
      if (file.name === "") {
        return;
      }
      this.$api
        .put(`attachments/${file.id}`, file)
        .then((response) => {
          this.setConfirmationMessage("File updated successfully!");
          this.getFiles();
        })
        .catch((error) => {
          console.error("Error updating file:", error);
        })
        .finally(() => {
          file.updateMode = false;
        });
    },

    deleteFile() {
      this.$api
        .delete(`attachments/${this.itemToDelete.id}`, this.itemToDelete)
        .then((response) => {
          this.setConfirmationMessage("File deleted successfully!");
          this.getFiles();
          this.showDeleteDialog = false;
        })
        .catch((error) => {
          console.error("Error deleting file:", error);
        })
        .finally(() => {
          this.itemToDelete = null;
        });
    },

    closeModal() {
      if (this.showUploadFileDialog) {
        this.$refs.fileForm.reset();
        this.$refs.fileForm.resetValidation();
        this.showUploadFileDialog = false;
      } else if (this.showCreateFolderDialog) {
        this.$refs.folderForm.reset();
        this.showCreateFolderDialog = false;
      } else {
        this.showDeleteDialog = false;
      }
    },

    setConfirmationMessage(message) {
      this.snackbarVisible = true;
      this.confirmationMessage = message;
    },

    updateItem(item) {
      item.updateMode = !item.updateMode;
      if (this.itemIsFolder(item)) {
        this.showFolderMenu[item.id] = false;
      } else {
        this.showFileMenu[item.id] = false;
      }
    },

    deleteItem(item) {
      this.itemToDelete = item;
      if (this.itemIsFolder(item)) {
        this.showFolderMenu[item.id] = false;
      } else {
        this.showFileMenu[item.id] = false;
      }
      this.showDeleteDialog = true;
    },

    handleDrop(event) {
      event.preventDefault();
      const files = Array.from(event.dataTransfer.files);

      for (const file of files) {
        this.draggedItem = file;
        this.fileToUpload = file;
        this.processDroppedFile();
      }
    },

    processDroppedFile() {
      if (
        Object.keys(this.highlightedFolders).length === 0 ||
        Object.values(this.highlightedFolders).every((value) => value === false)
      ) {
        this.uploadFile();
      }
    },

    moveItemToFolder(folderId) {
      setTimeout(() => {
        if (this.draggedItem) {
          if (this.itemIsFolder(this.draggedItem)) {
            this.draggedItem.parent = folderId;
            this.updateFolder(this.draggedItem);
          } else {
            if (this.draggedItem === this.fileToUpload) {
              this.uploadFile(folderId);
            } else {
              this.draggedItem.folder = folderId;
              this.updateFile(this.draggedItem);
            }
          }
          this.draggedItem = null;
          this.highlightedFolders = {};
        }
      }, 100);
    },

    getIconColor(icon) {
      const iconToColor = {
        "mdi-file-presentation": "var(--yellow)",
        "mdi-google-spreadsheet": "var(--green)",
        "mdi-movie": "var(--red)",
        "mdi-file-document": "var(--aqua)",
      };
      return iconToColor[icon] || "var(--aqua)";
    },

    saveYear(year) {
      this.attachment.associated_year = year.toString();
      this.activePicker = "YEAR";
      this.menu = false;
    },

    updateYear(file, year) {
      file.associated_year = year;
      this.updateFile(file);
      this.activePicker = "YEAR";
      this.menu = false;
    },
  },
};
</script>
