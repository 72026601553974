<template>
  <div class="chart-container">
    <div class="v-chart" ref="chartContainer"></div>
    <div v-if="noData" class="no-data-container">
      <v-icon color="grey lighten-1" size="48">mdi-database-off</v-icon>
      <p class="no-data-text">No Data Reported</p>
      <p class="no-data-subtext">
        Try adjusting your filters or check the data source.
      </p>
    </div>
  </div>
</template>

<style scoped>
.chart-container {
  width: 100%;
  height: 400px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.v-chart {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.no-data-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  animation: fadeIn 0.7s ease-in-out;
}

.no-data-text {
  font-size: 18px;
  font-weight: bold;
  color: #666;
}

.no-data-subtext {
  font-size: 14px;
  color: #999;
  margin-top: 8px;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>

<script>
import echarts from "echarts/lib/echarts";
import "echarts/lib/chart/pie";
import "echarts/lib/component/title";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/grid";
import "echarts/lib/component/legend";
export default {
  name: "PieChart",
  props: {
    title: {
      type: String,
      required: false,
    },
    series: {
      type: Array,
      required: true,
    },
    chartType: {
      type: String,
      default: "pie", // 'pie' or 'donut'
    },
  },
  data() {
    return {
      chart: null,
      noData: false,
    };
  },
  mounted() {
    this.initChart();
    window.addEventListener("resize", this.resizeChart);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resizeChart);
    if (this.chart) {
      this.chart.dispose();
    }
  },
  methods: {
    initChart() {
      const container = this.$refs.chartContainer;
      this.chart = echarts.init(container, null, { resize: true });
      this.updateChartOptions();
    },
    resizeChart() {
      if (this.chart) {
        this.chart.resize();
      }
    },
    updateChartOptions() {
      this.noData =
        this.series.length === 0 ||
        this.series.every((series) => !series.value);

      if (this.noData) {
        this.chart.clear();
        return;
      }

      const screenWidth = window.innerWidth;

      const options = {
        color: ["#5872c0", "#9eca7f", "#F5A623", "#D64242", "#F7E52D"],
        title: {
          text: this.title,
          left: "center",
          top: "2%",
          textStyle: {
            fontSize: screenWidth <= 1000 ? 12 : 16, // Adjust title size for mobile
            fontWeight: "bold",
          },
        },
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b} : {c} ({d}%)",
          textStyle: {
            fontSize: screenWidth <= 1000 ? 10 : 12, // Adjust tooltip text size for mobile
          },
          position: "bottom",
        },
        legend: {
          top: "8%",
          left: "center",
          textStyle: {
            fontSize: screenWidth <= 1000 ? 10 : 12, // Adjust legend text size for mobile
          },
        },
        series: [
          {
            name: this.title,
            type: "pie",
            radius: this.chartType === "donut" ? ["40%", "70%"] : "55%",
            center: ["50%", "50%"], // Center the pie chart
            data: this.series.map((item) => ({
              name: item.name,
              value: parseFloat(item.value), // Ensure the value is numeric
            })),
            label: {
              formatter: "{b}\n{d}%",
              position: "outside",
              fontSize: screenWidth <= 1000 ? 10 : 12, // Adjust label font size
            },
            labelLine: {
              length: screenWidth <= 1000 ? 5 : 10, // Shorten label lines on mobile
              length2: screenWidth <= 1000 ? 5 : 10,
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };
      this.chart.setOption(options);
    },
  },
  watch: {
    title: "updateChartOptions",
    series: "updateChartOptions",
    chartType: "updateChartOptions",
  },
};
</script>
