<template>
  <div>
    <div class="reporting-period-container">
      <!-- <h2 class="pb-2">Reporting Period</h2> -->
      <div class="box-container">
        <div class="switch-container">
          <div
            class="box-eigth text-caption font-weight-bold switch-monthly-label"
          >
            Monthly
          </div>
          <div class="box-third">
            <v-switch class="switch" v-model="isQuarterly" inset></v-switch>
          </div>
          <div
            class="box-eigth text-caption font-weight-bold switch-quarterly-label"
          >
            Quarterly
          </div>
        </div>
        <!-- <div class="box-third" v-if="selectedOption === 'Monthly'">
          <v-select
            v-model="selectedMonth"
            :items="availableMonths"
            label="Month"
            item-text="name"
            outlined
            dense
            return-object
            @change="getKPIsForSelectedMonth"
          ></v-select>
        </div>
        <div class="box-third" v-else>
          <v-select
            v-model="selectedQuarter"
            :items="quarterlyMonths"
            label="Quarter"
            outlined
            dense
            return-object
            @change="getQuarterlyKPIs"
          ></v-select>
        </div> -->
      </div>
    </div>
    <div
      v-for="(team, index) in Object.keys(this.partitionedKPIs)"
      :key="index"
      class="team-kpis"
    >
      <div>
        <h2 class="pb-4">{{ team }}</h2>
      </div>

      <!-- <div class="value-cards-container">
        <v-card
          v-for="kpi in getTeamValueCards(team)"
          :key="kpi.id"
          class="value-card pb-8"
          color="transparent"
          :elevation="0"
        >
          <v-card-title
            style="color: var(--aqua);"
            class="font-weight-black text-h5 pb-0 mt-n8"
            >{{ getMetricValue(kpi) }}</v-card-title
          >
          <span>{{ kpi.kpi_metric.name }}</span>
          <div>
            <v-tooltip
              bottom
              v-if="kpi.note"
              class="dashboard-tooltip"
              max-width="50vw"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" class="mr-1"
                  >mdi-information-box</v-icon
                >
              </template>
              <span>{{ kpi.note }}</span>
            </v-tooltip>
          </div>
        </v-card>
      </div> -->
      <div class="chart-cards-container">
        <v-card
          v-for="kpi in getTeamChartCards(team)"
          :key="kpi.id"
          class="chart-card pb-8"
          color="#FFFFFF"
          :elevation="4"
        >
          <div v-if="charts[kpi.id].chart_type === 'bar'">
            <bar-chart
              :title="charts[kpi.id].title"
              :series="charts[kpi.id].series"
              :categories="charts[kpi.id].categories"
              :orientation="charts[kpi.id].orientation"
              :xAxisTitle="charts[kpi.id].x_axis_title"
              :yAxisTitle="charts[kpi.id].y_axis_title"
              :chartType="charts[kpi.id].type"
              :isMonthly="!isQuarterly"
              :metric="kpi"
            />
          </div>

          <!-- <div v-if="charts[kpi.kpi_metric.id].chart_type == 'pie'">
            <pie-chart
              :title="charts[kpi.kpi_metric.id].title"
              :series="charts[kpi.kpi_metric.id].series"
              :chartType="charts[kpi.kpi_metric.id].type"
            />
          </div> -->
          <div v-if="charts[kpi.id].chart_type === 'line'">
            <line-chart
              :title="charts[kpi.id].title"
              :series="charts[kpi.id].series"
              :categories="charts[kpi.id].categories"
              :orientation="charts[kpi.id].orientation"
              :xAxisTitle="charts[kpi.id].x_axis_title"
              :yAxisTitle="charts[kpi.id].y_axis_title"
              :chartType="charts[kpi.id].type"
              :isMonthly="!isQuarterly"
              :metric="kpi"
            />
          </div>
        </v-card>
      </div>
    </div>
  </div>
</template>
<style>
.transparent-card {
  background-color: transparent;
  box-shadow: none;
  border: none;
}

.team-kpis {
  margin-bottom: 16px;
}

.value-cards-container,
.chart-cards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin: -8px;
}

.value-card {
  flex-basis: calc(24% - 16px);
  margin: 8px;
}

.chart-card {
  flex-basis: calc(50% - 32px);
  margin: 8px;
}

.dashboard-tooltip {
  z-index: 9999;
}

@media (max-width: 1000px) {
  .value-card {
    flex-basis: calc(50% - 16px);
  }
  .chart-card {
    flex-basis: calc(100% - 16px);
    margin: 8px 0;
  }
}

.reporting-period-container {
  display: inline-block;
}

.add-kpi-btn {
  float: right;
}

@media (max-width: 1000px) {
  .add-kpi-btn {
    align-self: flex-start;
  }
}

.close-button {
  position: absolute;
  top: 1px;
  right: 1px;
}

div.settings {
  display: grid;
  grid-template-columns: max-content max-content;
  grid-gap: 5px;
  align-items: center;
}

div.settings label {
  text-align: right;
}

.switch-container {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Ensure the labels and switch are evenly spaced */
  width: 100%;
  max-width: 300px; /* Adjust to control the container size */
  margin: 0 auto;
}

.switch-quarterly-label,
.switch-monthly-label {
  flex: 1; /* Make both labels take equal space */
  text-align: center; /* Center the label text */
  font-weight: bold;
  color: dimgrey;
  white-space: nowrap; /* Prevent text wrapping */
}

.switch-monthly-label {
  margin-right: 2px;
}
.v-switch {
  flex-shrink: 0; /* Prevent the switch from shrinking */
  margin: 0 10px; /* Even spacing around the switch */
}

@media (max-width: 1000px) {
  .switch-container {
    max-width: 250px; /* Adjust for smaller screens */
  }

  .switch-quarterly-label,
  .switch-monthly-label {
    font-size: 14px; /* Adjust font size on smaller screens */
  }

  .v-switch {
    margin: 0 5px; /* Adjust margin on smaller screens */
  }

  .switch-monthly-label {
    margin-right: 7px;
  }

  .switch-quarterly-label {
    margin-left: -9px;
  }
}

.v-input--switch:not(.v-input--switch--flat):not(.v-input--switch--inset)
  .v-input--switch__thumb {
  color: var(--aqua) !important;
}

.theme--light.v-input--switch .v-input--switch__track {
  color: var(--aqua) !important;
}

.v-application--is-ltr
  .v-input--switch--inset.v-input--is-dirty
  .v-input--selection-controls__ripple,
.v-application--is-ltr
  .v-input--switch--inset.v-input--is-dirty
  .v-input--switch__thumb {
  color: var(--aqua) !important;
}

.v-application--is-ltr
  .v-input--switch--inset
  .v-input--selection-controls__ripple,
.v-application--is-ltr .v-input--switch--inset .v-input--switch__thumb {
  color: var(--aqua) !important;
}

.team-kpis:not(:last-child) {
  padding-bottom: calc(2vw + 20px);
}
</style>

<script>
import BarChart from "./BarChart.vue";
import LineChart from "./LineChart.vue";
import PieChart from "./PieChart.vue";
import moment from "moment";
export default {
  components: { LineChart, BarChart },
  data() {
    return {
      isQuarterly: false,
      // selectedQuarter: "",
      // selectedOption: "Monthly",
      // selectedReportingDate: "",
      // months: [
      //   { name: "January", value: 1 },
      //   { name: "February", value: 2 },
      //   { name: "March", value: 3 },
      //   { name: "April", value: 4 },
      //   { name: "May", value: 5 },
      //   { name: "June", value: 6 },
      //   { name: "July", value: 7 },
      //   { name: "August", value: 8 },
      //   { name: "September", value: 9 },
      //   { name: "October", value: 10 },
      //   { name: "November", value: 11 },
      //   { name: "December", value: 12 },
      // ],
      kpis: [],
      // filteredKPIs: [],
      charts: {},
    };
  },
  computed: {
    // availableMonths() {
    //   return [...new Set(this.kpis.map((kpi) => kpi.reporting_date))]
    //     .map((month) => {
    //       const date = new Date(month);
    //       return {
    //         name: `${this.getMonthName(
    //           date.getUTCMonth()
    //         )} ${date.getUTCFullYear()}`,
    //         month: date.getUTCMonth() + 1,
    //         year: date.getUTCFullYear(),
    //         timestamp: date.getTime(),
    //       };
    //     })
    //     .sort((a, b) => b.timestamp - a.timestamp)
    //     .map(({ timestamp, ...rest }) => rest);
    // },

    partitionedKPIs() {
      const partitions = {};

      this.kpis.forEach((kpi) => {
        const teamName = kpi.team_name;
        if (!partitions[teamName]) {
          partitions[teamName] = [];
        }
        partitions[teamName].push(kpi);
      });

      Object.keys(partitions).forEach((teamName) => {
        partitions[teamName].sort((a, b) => {
          return (
            a.kpi_chart_maps[0].chart.position -
            b.kpi_chart_maps[0].chart.position
          );
        });
      });

      const sortedPartitions = {};
      Object.keys(partitions)
        .sort()
        .forEach((teamName) => {
          sortedPartitions[teamName] = partitions[teamName];
        });

      return sortedPartitions;
    },

    // quarterlyMonths() {
    //   const quarterMappings = { "1": "Q1", "4": "Q2", "7": "Q3", "10": "Q4" };
    //   const quarterRanges = [
    //     [1, 2, 3],
    //     [4, 5, 6],
    //     [7, 8, 9],
    //     [10, 11, 12],
    //   ];

    //   const years = [
    //     ...new Set(
    //       this.kpis.map((kpi) => new Date(kpi.reporting_date).getUTCFullYear())
    //     ),
    //   ];

    //   const quarters = years.flatMap((year) =>
    //     quarterRanges
    //       .filter((range) =>
    //         range.every((month) =>
    //           this.kpis.some((kpi) => {
    //             const date = new Date(kpi.reporting_date);
    //             return (
    //               date.getUTCMonth() + 1 === month &&
    //               date.getUTCFullYear() === year
    //             );
    //           })
    //         )
    //       )
    //       .map((range) => {
    //         const startMonth = this.getMonthName(range[0] - 1);
    //         const endMonth = this.getMonthName(range[2] - 1);
    //         const quarterText = `${quarterMappings[range[0]]} ${year}`;
    //         const detailedQuarterText = `${quarterText} (${startMonth} - ${endMonth})`;
    //         return {
    //           name: `${endMonth} ${year}`,
    //           text: detailedQuarterText,
    //           value: range[0],
    //           year,
    //           timestamp: new Date(year, range[0] - 1).getTime(),
    //         };
    //       })
    //   );

    //   // Sort by the 'timestamp' property in descending order
    //   quarters.sort((a, b) => b.timestamp - a.timestamp);

    //   // Remove the timestamp property before returning the result
    //   return quarters.map(({ timestamp, ...rest }) => rest);
    // },
  },
  watch: {
    isQuarterly(currentValue) {
      if (currentValue) {
        this.getQuarterlyKpiCharts();
      } else {
        this.getKpiCharts();
      }
    },
  },

  created() {
    this.getLatestKPIs();
  },
  methods: {
    // async getKPIsForSelectedMonth() {
    //   try {
    //     this.filteredKPIs.splice(0, this.filteredKPIs.length);
    //     let url = `kpis?month=${this.selectedMonth.month}&year=${this.selectedMonth.year}`;
    //     while (url) {
    //       let response = await this.$api.get(url);
    //       this.filteredKPIs.push(...response.data.results);
    //       url = response.data.next;
    //     }
    //     this.getKpiCharts();
    //   } catch (error) {
    //     if (error.response && error.response.status === 403) {
    //       this.$router.push({ name: "403" });
    //     } else {
    //       console.error(error);
    //     }
    //   } finally {
    //     this.selectedQuarter = "";
    //   }
    // },

    // getQuarterlyKPIs() {
    //   this.filteredKPIs.splice(0, this.filteredKPIs.length);
    //   this.$api
    //     .get(`kpis/quarterly_metrics?quarters=${this.selectedQuarter.value}`)
    //     .then((response) => {
    //       this.filteredKPIs = response.data;
    //       this.getQuarterlyKpiCharts();
    //     })
    //     .finally(() => {
    //       this.selectedMonth = "";
    //     });
    // },

    async getLatestKPIs() {
      try {
        let url = "kpis/metrics";
        while (url) {
          let response = await this.$api.get(url);
          this.kpis.push(...response.data.results);
          url = response.data.next;
        }
        // this.selectedMonth = this.availableMonths[0];
        // this.getKPIsForSelectedMonth();
        this.getKpiCharts();
      } catch (error) {
        console.log(error);
        if (error.response.status === 403) {
          this.$router.push({ name: "403" });
        }
        console.log(error);
      }
    },

    getKpiCharts() {
      this.charts = {};
      this.$api.get("kpis/values/charts").then((response) => {
        this.charts = response.data;
      });
    },

    getQuarterlyKpiCharts() {
      this.charts = {};
      this.$api
        .get("kpis/values/quarterly_charts?quarters=1,4,7,10")
        .then((response) => {
          this.charts = response.data;
        });
    },

    // getMonthName(monthIndex) {
    //   const months = this.months.map((month) => month.name);
    //   return months[monthIndex];
    // },

    // getCardClass(kpi) {
    //   if (this.isValueCard(kpi)) {
    //     return "value-card";
    //   }
    //   return "chart-card";
    // },
    // isValueCard(kpi) {
    //   return !kpi.kpi_metric.kpi_chart_maps.length;
    // },
    // getTeamValueCards(team) {
    //   return this.partitionedKPIs[team].filter((kpi) => this.isValueCard(kpi));
    // },

    getTeamChartCards(team) {
      let teamKPIs = this.partitionedKPIs[team].filter(
        (kpi) => this.charts && kpi.id in this.charts
      );
      return teamKPIs;
    },
  },
};
</script>
